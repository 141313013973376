<template>
    <div class="col-md-12 loading stop-scrolling shadow-load">
        <img class="" style="padding-top: 200px; width: 400px; ;cursor:pointer" id="logo" src="./../assets/logos/LogoFletram.jpg">
        <h6 style="line-height:3; padding-bottom: 50px; font-size: 33px;font-weight: bolder;" class="text-center">
            <strong>
                Espere...
            </strong>
        </h6>
    </div>
</template>

<script>

export default {
  name: 'shadowLoad',
  components: {
  }
}
</script>

<style>
.loading {
    background-color: #ffffff;
}

.shadow-load {
    position: absolute;
    /*background-color: #3e4650 !important;*/
    background-color: #ffffff !important;    
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 911;
}
.stop-scrolling {
  /* height: 100%;
  overflow: hidden; */
  position: fixed; 
  overflow-y: scroll;
  top: 0; right: 0; bottom: 0; left: 0;
  width: 105%;
}
</style>
