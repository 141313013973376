<template>
  <div v-if="loading">
    <loading></loading>
  </div>
  <div v-else id="inicio" class="index">

    <div class="container animated fadeInDown">
      <div class="col-md-12 bloqueGeneral">

        <!-- Titulo -->
        <div class="col-md-12 animated fadeInUp">
          <span>
            <h3 class="title">{{ data.inicio_title }}</h3>
          </span>
        </div>

        <!-- Ubicacion de texto -->
        <div class="row">
          <div class="col-md-5"></div>
          <div class="col-md-7 ubicacionTexto">{{ data.inicio_ubicacion }}</div>
        </div>

        <!-- Subtitulo y Mapa -->
        <div class="row">
          <div class="col-md-5 bloqueSubtitulo">
            <span class="subtitulo">
              <strong>
                {{ data.inicio_subtitle_1 }} <br> <span class="subtituloMarker"> {{ data.inicio_subtitle_2 }}</span> <br> {{ data.inicio_subtitle_3 }}
              </strong>  
            </span>
          </div>
          
          <div class="col-md-7 bloqueMapa">
            <img class="mapaCelaya" src="./../assets/img/punteroMapa.svg" alt="">
            <img class="punteroCelaya" src="./../assets/img/celayaMap.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from './../components/loading.vue'

export default {
  name: 'index',
  components: {
    loading
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.data = JSON.parse(window.localStorage.getItem('lang')) 
      this.loading = false
    }
  },
  data() {
    return {
      loading: true,
      data: JSON.parse(window.localStorage.getItem('lang')) 
    }
  }
}
</script>

<style scoped>
.index {
  padding-top: 111px;
}

.index .title {
  color: rgb(44 62 80); 
  font-size: 60px; 
  font-weight: bolder;
  text-align: center;
  padding-bottom: 30px;
}

.index .bloqueGeneral {
  padding-top: 100px; 
  padding-bottom: 200px;
}

.index .ubicacionTexto {
  text-align: center; 
  top: 30px;
  left: 30px;
}

.index .bloqueSubtitulo {
  padding-top: 100px;
}

.index .subtitulo {
  text-align: center; 
  font-size: 50px;
}

.index .subtituloMarker {
  color: #dd132a;
}

.index .bloqueMapa {
  padding-top: 50px;
}

.index .mapaCelaya {
  width: 120px; /* Ajusta según necesites */
  position: relative;
  left: 190px;
}

.index .punteroCelaya {
  width: 333px; /* Ajusta según necesites */
}

@media (max-width: 991px) {
  .index {
    padding-top: 111px;
  }

  .index .title {
    font-size: 40px; /* Ajusta según necesites */
  }

  .index .bloqueGeneral {
    padding-top: 100px; 
    padding-bottom: 100px;
  }

  .index .ubicacionTexto {
    text-align: center; 
    top: 250px; /* Ajusta según necesites */
    left: -130px;
  }

  .index .bloqueSubtitulo {
    padding-top: 0px;
  }

  .index .subtitulo {
    font-size: 30px; /* Ajusta según necesites */
    text-align: center;
  }

  .index .bloqueMapa {
    padding-top: 50px;
    margin-left: 0px;
  }

  .index .mapaCelaya {
    width: 100px; /* Ajusta según necesites */
    position: relative;
    left: -40px;
    top: 260px; /* Ajusta según necesites */
  }

  .index .punteroCelaya {
    width: 400px; /* Ajusta según necesites */
  }
}

@media (max-width: 768px) {
  .index {
    padding-top: 111px;
  }

  .index .title {
    font-size: 40px; /* Ajusta según necesites */
  }

  .index .bloqueGeneral {
    padding-top: 100px; 
    padding-bottom: 100px;
  }

  .index .ubicacionTexto {
    text-align: center; 
    top: 250px; /* Ajusta según necesites */
    left: 0px;
  }

  .index .bloqueSubtitulo {
    padding-top: 0px;
  }

  .index .subtitulo {
    font-size: 30px; /* Ajusta según necesites */
    text-align: center;
  }

  .index .bloqueMapa {
    padding-top: 50px;
    margin-left: 0px;
  }

  .index .mapaCelaya {
    width: 100px; /* Ajusta según necesites */
    position: relative;
    left: -40px;
    top: 260px; /* Ajusta según necesites */
  }

  .index .punteroCelaya {
    width: 400px; /* Ajusta según necesites */
  }
}
</style>
